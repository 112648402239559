import React, { useState } from "react";
import PopInFiche from "../popInFiche";
import { Modal } from "@mui/material";
import { donneesFiches } from "../../donnees/donneesFiches";

export const LinkText = (props: any) => {
  const [fiche, setFiche] = useState(0);

  const [openFiche, setOpenFiche] = useState(false);

  const handleOpenFiche = () => {
    setOpenFiche(true);
  };
  const handleCloseFiche = () => {
    setOpenFiche(false);
  };

  const retour = (id: number) => {
    setFiche(id);
    handleOpenFiche();
  };

  const handleClick = () => {
    return retour(props.click - 1);
  };

  return (
    <>
      <span className="blod bouton" onClick={handleClick}>
        {props.field}
      </span>

      <Modal
        open={openFiche}
        onClose={handleCloseFiche}
        aria-labelledby=""
        aria-describedby=""
      >
        <PopInFiche fiche={donneesFiches[fiche]} />
      </Modal>
    </>
  );
};
