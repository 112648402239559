import Fiche from "../modeles/fiche";

export const donneesFiches: Fiche[] = [
    {
        id: 1,
        nom: "Id Formation",
        presentation: "ID Formation est un centre de formation qui forme et accompagne les demandeurs d'emploi dans leur insertion professionnelle. Avec 213 lieux de formation, ID Formation est une Société Coopérative de Production (SCOP) qui accompagne salariés et demandeurs d’emploi dans leurs projets d’évolution et de transition professionnelle.",
        image: "/assets/images/logo_id_formation_1.svg",
        lien: "https://id-formation.com",
    },
    {
        id: 2,
        nom: "Titre professionnel Concepteur développeur d'applications",
        presentation: "Le concepteur développeur d'applications conçoit et développe des services numériques à destination des utilisateurs en respectant les normes et standards reconnus par la profession et en suivant l'état de l'art de la sécurité informatique à toutes les étapes. Le concepteur développeur d'applications conçoit et développe des applications sécurisées, tels que des logiciels d'entreprise, des applications pour mobiles et tablettes, ainsi que des sites Web. Il respecte la réglementation en vigueur, identifie les besoins en éco-conception et applique les procédures qualité de l'entreprise. Il contribue à la réussite du projet en termes de satisfaction des besoins des utilisateurs, gestion de projet, qualité, coût et délai. La sécurité de l'application est pour lui une préoccupation constante.",
        image: "/assets/images/FC_Certification_RNCP_Bleu_mod.png",
        lien: "https://www.francecompetences.fr/recherche/rncp/37873/",
    },
    {
        id: 3,
        nom: "Les zelliges arabo-andalouses",
        presentation: "Le terme 'zellige' désigne un type de mosaïque ornementale dans le domaine de l'architecture islamique. Les éléments unitaires, appelés « tesselles », sont des morceaux de carreaux de faïence colorés monochrome artisanaux. Ces morceaux de terre cuite émaillées sont découpés un à un et assemblés sur un lit de mortier pour former un assemblage géométrique polychrome.",
        image: "/assets/images/carrelage_carre.svg",
        lien: "https://fr.wikipedia.org/wiki/Zellige",
    },
    {
        id: 4,
        nom: "Annaire en React et Typescript",
        presentation: `Ce Week-end, j'ai fini la mise en page de 'Annuaire', Un petit programme de carnet de contacts permettant à partir d'un fichier Json de base d'y ajouter, modifier et supprimer des fiches.<br/>
        Ce programme va évoluer tout le long de ma formation comme le présent site pour y intégrer les nouveautés que j'aurai apprises depuis cette version.<br/>
        Beaucoup d'améliorations sont encore à faire telles que :
        <ul>
        <li>Ajouter une base de données MySQL pour conserver les modifications</li>
        <li>Dans la fenêtre de création et de modification des fiches, la possibilité d'y mettre sa propre photo ou avatar; c'est le seul élément immodifiable de la fiche pour l'instant</li>
        <li>Un programme de création de VCARDs et de QRCode pourra y être apporter aussi pour plus de fonctionnalités</li>
        <li>Une autre possibilité est la vue par un pop-up d'une carte pour pouvoir voir où se trouve l'adresse indiquée.</li>
        Un back-end n'est pas à l'ordre du jour vu la simplicité de l'application mais pourquoi pas en faire un en Java par pur excès de zèle !`,
        image: "https://coachid.tsao-tsao.com/assets/images/annuaire.png",
        lien: "https://annuaire.tsao-tsao.com",
    }
];
