import {
  Box,
  Card,
  CardContent,
  CardMedia,
  /* Link, */ Typography,
} from "@mui/material";
import Fiche from "../../modeles/fiche";
import { Trans, useTranslation } from "react-i18next";
import "./style.css";
import { LinkTextHref } from "../lienText2";
import { LinkTextSpan } from "../lienText3";

interface Props {
  fiche: Fiche;
}

const PopInFiche = ({ fiche }: Props) => {

  const { t } = useTranslation();

  const lien = "<link2>" + t("lienspan." + fiche.id) + "</link2>";

  return (

    <Card
      className="ficheDetail"
      sx={{ display: "flex", alignItems: "start", gap: "20" }}
    >
      <CardMedia
        component="img"
        image={fiche.image}
        alt={"Logo " + t("lientxt." + fiche.id)}
        sx={{ width: 200 }}
      />
      <CardContent>
        <Box display={"flex"} >
          <Typography variant="h1" className="titre">
            <Trans
              i18nKey={lien}
              components={{
                link2: <LinkTextHref to={fiche.lien} title={t("lientxt." + fiche.id)} />,
                link1: <LinkTextSpan />,
              }}
            />
          </Typography>
        </Box>
        <Box display={"flex"} className="txt">
          <Typography variant="body1">
            {t("presentation." + fiche.id)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PopInFiche;
