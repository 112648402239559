import React, { useState } from 'react';
/* import logo from './logo.svg'; */
import './App.css';
import Bienvenue from "./pages/bienvenue";
import Header from './components/header';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/login';
import PageUn from './pages/pageUn';
import PageDeux from './pages/pageDeux';
import News from './pages/news';
import Works from './pages/works';

function App() {

  // Zone pour faire plein de trucs
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);

  // Ici on construit l'interface

  return (

    <div className="App">
      {/* <header className="App-header">
      </header> */}
      <Header
        setIsAuthenticated={setIsAuthenticated}
        isAuthenticated={isAuthenticated}
      />

      <main>
        {isAuthenticated ? (
          <Routes>
            <Route path="/" element={<Bienvenue />} />
            <Route path="/news" element={<News />} />
            <Route path="/works" element={<Works />} />
            <Route path="/suite" element={<PageUn />} />
            <Route path="/etencore" element={<News />} />
          </Routes>
        ) : (
          <Login setIsAuthenticated={setIsAuthenticated} />
        )}
      </main>
    </div>
  );
}

export default App;
