import { Box, Typography } from "@mui/material";
import { LinkTextSpan } from "../../components/lienText3";
import { Trans } from "react-i18next";
import { t } from "i18next";

const Works = () => {

    return (
        <>
            <Box className="txt">
                <Typography variant="h1" className="txt titre">
                    <Trans
                        i18nKey={t("works.title")}
                        components={{
                            link1: <LinkTextSpan />,
                        }}
                    />
                </Typography>
            </Box>
        </>
    );
};

export default Works;