import React from 'react';
import { Trans } from 'react-i18next';
import { LinkText } from '../lienText';

interface Props {
    link1: string;
    link2: string;
    link3: string;
}

const AddText = ({ link1, link2, link3 }: Props) => {

    return (
        <>
            <Trans
                i18nKey={link1}
                components={{
                    link1: <LinkText field={link2} click={link3} />
                }}
            />
        </>
    );
};

export default AddText;
