
import { Box, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import AddText from "../../components/addText";

import "./style.css";
import { LinkTextSpan } from "../../components/lienText3";

const Welcome = () => {

  const { t } = useTranslation();

  return (
    <>
      <Box className="txt">
        <Typography variant="h1" className="txt titre">
          <Trans
            i18nKey={t("lienspan.3")}
            components={{
              link1: <LinkTextSpan />,
            }}
          />
        </Typography>
      </Box>
      <Box className="paragraphe">
        <Typography variant="body1" className="typo_1">
          <AddText link1={t("accueil.1")} link2={t("lientxt.1")} link3={"1"} />
        </Typography>
        <Typography variant="body1" className="typo_2">
          <AddText link1={t("accueil.2")} link2={t("lientxt.2")} link3={"2"} />
        </Typography>
        <Typography variant="body1" className="typo_3">
          <AddText link1={t("accueil.3")} link2={t("lientxt.3")} link3={"3"} />
        </Typography>
      </Box>
      <Box className="txt enterSite bouton2">
        <NavLink to="/news">
          <Typography variant="body1" className="txt bouton">
            {t("accueil.4")}
          </Typography>
        </NavLink>
      </Box>
    </>
  );
};

export default Welcome;
