import React from 'react';

export const LinkTextHref = (props: any) => {
    return (
        <div className="titreLien">
            <a href={props.to || '#'} target="_blank" title={props.title || ''}>
                {props.children}
            </a>
        </div>

    );
};