import React from 'react';

// LinkText.jsx

import "./style.css";

export const LinkTextSpan = (props: any) => {

    return (
        <span>{props.children}</span>

    );
};