import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { LinkTextSpan } from "../../components/lienText3";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { donneesFiches } from "../../donnees/donneesFiches";
import { NavLink } from "react-router-dom";

const News = () => {

    return (
        <>
            <Box className="txt">
                <Typography variant="h1" className="txt titre">
                    <Trans
                        i18nKey={t("news.title")}
                        components={{
                            link1: <LinkTextSpan />,
                        }}
                    />
                </Typography>
            </Box>
            <Box>
                <Card
                    className="ficheDetail2"
                    sx={{ display: "flex", alignItems: "start", gap: "20" }}
                >
                    <CardMedia
                        component="img"
                        image={donneesFiches[3].image}
                        alt={donneesFiches[3].nom}
                        sx={{ width: 336 }}
                    />
                    <CardContent>
                        <Box display={"flex"} >
                            <Typography variant="h2" className="txt titre">
                                {donneesFiches[3].nom}
                            </Typography>
                        </Box>
                        <Box display={"flex"} className="txt">


                            <Typography variant="body1">
                                <div dangerouslySetInnerHTML={{ __html: donneesFiches[3].presentation }} />
                            </Typography>
                        </Box>
                        <Box className="txt enterSite2 bouton2">
                            <NavLink to={t(donneesFiches[3].lien)}>
                                <Typography variant="body1" className="txt bouton">
                                    Vous pouvez retrouver le programme ici.
                                </Typography>
                            </NavLink>
                        </Box>
                    </CardContent>
                </Card>
            </Box>



        </>
    );
};

export default News;