import { AppBar, Avatar, Box, Button, IconButton, ToggleButton, ToggleButtonGroup, Toolbar, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import "./style.css";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  isAuthenticated: boolean;
  setIsAuthenticated: Function;
}

const Header = ({ isAuthenticated, setIsAuthenticated }: Props) => {

  const { t, i18n } = useTranslation();

  const toggleLanguage = (event: any,
    /*  event: React.MouseEvent<HTMLElement>, */
    newLanguage: string
  ) => {
    i18n.changeLanguage(newLanguage);

    /*    alert(event.target.innerHTML); */
    if ((newLanguage !== null) && (event.target instanceof HTMLElement) && ((event.target.innerHTML === "FR") || (event.target.innerHTML === "EN"))) {
      for (let i = 0; i < event.target.parentNode.parentNode.children.length; i++) {
        if (event.target.parentNode.parentNode.children[i].children[0].innerHTML == newLanguage.toUpperCase()) {
          event.target.parentNode.parentNode.children[i].children[0].style.color = "white";
        } else {
          event.target.parentNode.parentNode.children[i].children[0].style.color = "black"; // ok
        }
      }
    }
  };

  return (
    <Box sx={{
      flexGrow: 1,
      fontWeight: 500,
      fontSize: 15,
      letterSpacing: 1.5,
    }}
    >
      <AppBar position="static" sx={{ backgroundColor: "#808080" }}>
        <Toolbar sx={{ flexGrow: 1 }}>
          <Box sx={{
            display: "flex", flexGrow: 2
          }}
          >
            <Typography component="div" sx={{}}>
              <NavLink
                to="/"
                aria-label="menu"
              >
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2, color: "white" }}
                >
                  <MenuIcon />
                </IconButton>
              </NavLink>
              <NavLink to="/news">
                {t("news.title")}
              </NavLink>
              <NavLink to="/works">
                {t("works.title")}
              </NavLink>
            </Typography>
          </Box>
          <Box sx={{
            display: "flex",
            justifyContent: "right",
            flexGrow: 2
          }}
          >
            <Button color="inherit"
              onClick={() => {
                isAuthenticated ? setIsAuthenticated(false) : setIsAuthenticated(true);
              }}
            >
              {isAuthenticated ? t("common.logout") : t("common.login")}
            </Button>
            <ToggleButtonGroup
              value={i18n.language}
              exclusive
              size="small"
              sx={{ margin: "1em" }}
              onChange={toggleLanguage}
            >
              <ToggleButton value="fr">
                <Typography
                  fontSize="small"
                  sx={{
                    width: "20px",
                    height: "20px",
                    color: "white"
                  }}
                >
                  FR
                </Typography>
              </ToggleButton>
              <ToggleButton value="en">
                <Typography
                  fontSize="small"
                  sx={{
                    width: "20px",
                    height: "20px",
                    color: "black"
                  }}
                >
                  EN
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
            <Box sx={{ margin: "1em" }}>
              {isAuthenticated ? <Avatar /> : <></>}
            </Box>
          </Box>

        </Toolbar>
      </AppBar>
    </Box >
  );
}

export default Header;